import React from "react"

export const TITLES = {
  services: {
    title: (
      <>
        full list of <strong>services</strong>
      </>
    ),
    strongText: "one-stop-shop software development company",
    uppercase: (
      <>
        A wide range of
        <br /> services
      </>
    ),
    withIndent: false,
  },
  testimonials: {
    title: (
      <>
        clients’ <strong>testimonials</strong>
      </>
    ),
    strongText: (
      <>
        we always get positive recommendations from our clients, it’s a good
        indicator of our services
      </>
    ),
    uppercase: (
      <>
        Trusted
        <br /> partnership
      </>
    ),
    withIndent: false,
  },
}

export const ANIMATED_COUNTERS = [
  {
    id: 1,
    text: (
      <>
        Years in the IT market <br />
      </>
    ),
    start: 0,
    end: 9,
    delay: 0,
    suffix: "+",
  },
  {
    id: 2,
    text: "Products we built",
    end: 85,
    delay: 0,
    suffix: "+",
  },
  {
    id: 3,
    text: "Cities worldwide",
    start: 0,
    end: 35,
    delay: 0,
  },
  {
    id: 4,
    text: "Industries experience",
    start: 0,
    end: 19,
    delay: 0,
  },
  {
    id: 5,
    text: "Professionals on board",
    start: 0,
    end: 120,
    delay: 0,
    suffix: "+",
  },
]

export const CUSTOMERS = [
  { name: "Casadesk", image: "logo-Casadesk" },
  { name: "Recruiting Plan", image: "logo-RecruitingPlan" },
  { name: "Sh1ft", image: "logo-Sh1ft" },
  { name: "Underdock", image: "logo-Underdock" },
  { name: "Dope 360", image: "logo-Dope360" },
  { name: "Salt", image: "logo-Salt" },
  { name: "Outsail", image: "logo-Outsail" },
  { name: "Kamsa", image: "logo-Kamsa" },
  { name: "Vendorstan", image: "logo-Vendorstan" },
  { name: "Norconsult", image: "logo-Norconsult" },
  { name: "Ella", image: "logo-Ella" },
  { name: "Age Line", image: "logo-AgeLine" },
  { name: "Minutes Depot", image: "logo-MinutesDepot" },
  { name: "ELMS", image: "logo-ELMS" },
  { name: "Intuilize", image: "logo-Intuilize" },
  { name: "MonacoTelecom", image: "logo-MonacoTelecom" },
  { name: "Euronavigator", image: "logo-Euronavigator" },
  { name: "Call Criteria", image: "logo-CallCriteria" },
  { name: "Ivala", image: "logo-Ivala" },
  { name: "Zala", image: "logo-Zala" },
]

export const SERVICES_LIST = [
  {
    id: 1,
    title: "Custom Software Development",
    text:
      "Get beautiful and easy-to-use software that improves the performance of your business and leads to a breakthrough in the entire industry.",
    link: "/custom-software-development/",
    icon: "icon-settings",
  },
  {
    id: 2,
    title: "MVP Development",
    text:
      "Have a business idea? Start with a Minimum Viable Product. It allows you to test the concept's viability and save you from vast investments in creating a full-fledged digital product.",
    link: "/mvp-development/",
    icon: "icon-rocket",
  },
  {
    id: 3,
    title: "Digital Product Design",
    text:
      "Our experienced designers create beautiful digital designs for web and mobile applications with well-thought-out user experiences. We create a digital product that improves customer engagement.",
    link: "/product-design/",
    icon: "icon-pencil",
  },
  {
    id: 4,
    title: "Nearshore Software Development",
    text:
      "Hire a world-class, highly-productive software development team for your digital project.",
    link: "/dedicated-team/",
    icon: "icon-users",
  },
  {
    id: 5,
    title: "Product Strategy",
    text:
      "Make smarter decisions early with our experts in System Architecture, Product Design, Marketing, Planning, and Budgeting, that ready to empower you and help you to achieve better results.",
    link: "/product-consulting/",
    icon: "icon-message",
  },
  {
    id: 6,
    title: "Web Development",
    text:
      "We have extensive expertise in delivering software development projects and ensuring our clients receive beautiful, custom web applications to encourage digital transformation of their business.",
    link: "/web-development/",
    icon: "icon-monitor",
  },
  {
    id: 7,
    title: "Mobile Apps Development",
    text:
      "Make your business closer to your clients through a mobile app (Android and IOS) to improve customer engagement and cover your business needs.",
    link: "/mobile-development/",
    icon: "icon-tablet",
  },
  {
    id: 8,
    title: "Dashboard Development",
    text:
      "We have extensive knowledge in developing high-load interactive dashboards with modern infographics. The dashboards we build are a custom solution to have in depth understanding of business process.",
    link: "/dashboard/",
    icon: "icon-connection",
  },
]

export const TESTIMONIALS = [
  {
    id: "Kapil-Gupta",
    name: "Kapil Gupta",
    position: "CEO, Founder",
    text:
      "We’ve been working with LaSoft for almost two years, and it’s been a fantastic experience. They have a strong work ethic, deliver high-quality results, and communicate clearly and responsively. What stands out is their commitment to understanding our needs and going above and beyond to help us. Lasoft has been an amazing partner in building our platform.",
    image: "Kapil-Gupta",
  },
  {
    id: "OutSailt",
    name: "Brett Ungashick",
    position: "Founder of OutSail",
    text:
      "The team's work ethic and communication are of the highest quality. LaSoft has created a user-friendly web experience that has resulted in increased customers and more efficient purchases. The team meets the project's deadlines and offers high-quality communication through email and biweekly virtual meetings. LaSoft responds promptly to the client's requests.",
    image: "Brett-Ungashick",
  },
  {
    id: "Sh1ft",
    name: "Samantha Crossman",
    position: "General Manager at Sh1ft",
    text:
      "All of our requests were understood clearly and implemented correctly. Their communication on progress and suggestions was great, and even though these tasks took longer than planned, they kept us constantly updated on progress. It makes our lives so much easier to know we have someone who understands what we need and can translate that clearly to the wider team whilst keeping us regularly updated.",
    image: "Samantha-Crossman",
  },
  {
    id: "Blue Manor Academy",
    name: "Britton LaTulippe",
    position: "President of Blue Manor Academy",
    text:
      "In many ways, LaSoft saved our company. Our online academy failed to grow for several years, and we thought it was just our curriculum. We kept improving the curriculum but didn't see an increase in subscriptions. Finally, we hired LaSoft, and they redesigned our checkout system and main dashboard. We immediately saw an increase in subscribers. Now, we have been working with LaSoft for three years and could not be happier!",
    image: "Britton-LaTulippe",
  },
  {
    id: "KAMSA",
    name: "Matt Harbes",
    position: "CTO of KAMSA",
    text:
      "We've worked with LaSoft for several years and have always been impressed. Their passion and dedication to Kamsa is second to none, and they are always looking for new ways to improve and ensure the success of our team. We couldn't ask for a better partnership.",
    image: "Matt-Harbes",
  },
  {
    id: "Casadesk",
    name: "Job Breur",
    position: "Chief Starting Officer",
    text:
      "LaSoft provided me with a very professional team that delivered good results during the first project. Thanks to this good experience, I had no doubt that I would choose the LaSoft team for a new project. They have all the necessary experience, including UX/UI, programming, testing, project management, and cloud deployment. I enjoy working with guys and trust we've started a long-term relationship.",
    image: "Job-Breur",
  },
  {
    id: "Minutes Depot",
    name: "Louis Bedard",
    position: "CEO at Minutes Depot",
    text:
      "While working on a project, the entire team covers all stages and   needs. We recommend them (LaSoft) without hesitation to anyone looking for high-quality and professional programming for a reasonable price.",
    image: "Louis-Bedard",
  },
  {
    id: "Thinking Machine",
    name: "Jason Docter",
    position: "Owner of Thinking Machine",
    text:
      "LaSoft has an amazing team of highly skilled web designers and programmers. They were able to execute the ideas we had in our head and build a simple website with ease of functionality. They are always available for feedback or quick adjustments. Highly recommended.",
    image: "Jason-Docter",
  },
  {
    id: "Britesiders",
    text:
      "We have been very impressed with LaSoft’s ability to quickly understand a project and deliver iterations as requested. LaSoft delivered a reliable MVP that is serving as a proof-of-concept. It’s been critical in establishing the client’s business. The project manager was responsive and responsible, making the engagement a pleasurable one. They quickly understood project requirements and showcased their expertise.",
    name: "Sam Barberie",
    position: "COO of Britesiders",
    image: "Sam-Barberie_",
  },
  {
    id: "EuroNavigator",
    text:
      "NGO “Ukrainian Centre for European Policy” started to cooperate with LaSoft almost a year and a half ago and appreciate its team for invaluable support in development of a back-end database and graphical interface for an analytical monitoring product “AA Navigator”. Creative technical and design solutions proposed by LaSoft team were the core inputs into re-launching and upgrading of this analytical tool that is widely used today from the highest governmental level to ordinary citizens who are interested in Ukraine’s European integration for comprehensive monitoring and assessment the progress in implementation of the Association Agreement between the EU and Ukraine.",
    name: "Liubov Akulenko",
    position: "Executive director of UCEP",
    image: "Liubov-Akulenko_",
  },
  {
    id: "Wristo",
    text:
      "This is a young, energetic and yet very experienced team which demonstrated an extremely professional approach.",
    name: "Matvey Smirnov",
    position: "Senior R&D Director at PepsiCo",
    image: "Matvey-Smirnov",
  },
  {
    id: "Vendorstan",
    text:
      "I interviewed many Ukrainian RoR developer companies before I decided to give LaSoft a chance. They had the best combination of price and straight-forward communication style. I loved that they took time to flesh out system requirements before we signed a formal contract. I knew how many releases they recommended, what features would be included in each, who would be on the team and what their hourly rates would be. The team is very hard working and takes pride in their work.",
    name: "Olga Yurovski",
    position: "CEO of Shopperations Research & Technology",
    image: "Olga-Yurovski",
  },
]
